import { Media } from '@raylink/common/theme'
import styled from 'styled-components'
import BannerBg from '../../images/about/banner-bg.webp'
import BannerBgPhone from '../../images/about/banner-bg-phone.png'
import HonorBg from '../../images/about/honor-bg.webp'

export const BannberWrapper = styled.div`
  min-width: 1200px;
  height: 660px;
  position: relative;
  background: url(${BannerBg}) no-repeat;
  background-size: cover !important;
  background-position: center center;
  display: flex;
  ${Media.ipad`
    min-width: 944px;
  `}
  ${Media.phone`
    min-width: 100%;
    height: 10.92rem;
    background: url(${BannerBgPhone}) no-repeat;
  `}
`

export const TitleWrapper = styled.div`
  width: 1200px;
  margin: 270px auto 0;
  h1 {
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0;
    font-size: 52px;
    font-weight: 700;
    line-height: 78px;
    width: 512px;
    color: #212121;
  }
  p {
    margin-top: 12px;
    color: #464646;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }
  .mark-color {
    color: #11c871;
  }
  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 8px;
    border-radius: 50%;
    background: #212121;
  }
  ${Media.ipad`
    h1, p{
      margin-left: 40px;
    }
  `}
  ${Media.phone`
    width: 5.94rem;
    padding: 1.82rem 0 0 0;
    margin: 0 auto;
    p,h1{
      text-align: center;
    }
    p{
      width: 3.82rem;
      margin: 0.18rem auto 0;
      font-size: 0.32rem;
      line-height: 0.48rem;
    }
    h1{
      flex-direction: column;
      width: 5.94rem;
      margin: 0 auto;
      font-size: 0.8rem;
      line-height: 1.06rem;
    }
    .dot {
      display: none;
    }
  `}
`

export const AboutUs = styled.div`
  padding: 110px 0 100px;
  .about-box {
    display: flex;
    justify-content: center;
  }
  .about-image {
    width: 546px;
    height: 320px;
    margin-right: 32px;
    border-radius: 16px;
  }
  .about-desc {
    width: 600px;
    margin-left: 32px;
    text-align: left;
    display: flex;
    flex-direction: column;
    h2 {
      color: #212121;
      font-size: 32px;
      text-align: left;
      line-height: 1.5;
      margin: 0;
      font-weight: 500;
      :after {
        content: '';
        display: block;
        width: 26px;
        height: 4px;
        background: #11c871;
        border-radius: 90px;
        margin-top: 13px;
        margin-bottom: 20px;
      }
    }
    p {
      color: #595959;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
    .first-p {
      margin-bottom: 20px;
    }
  }
  ${Media.ipad`
    padding: 64px 0;
    .about-box{
      flex-direction: column;
      align-items: center;
      flex-direction: column-reverse;
      gap: 40px;
    }
    .about-image{
      width: 810px;
      height: 474px;
      margin: 0;
    }
    .about-desc {
      width: 810px;
      margin: 0 auto;
      h2 {
        margin-bottom: 40px;
        text-align: center;
        :after {
          display: none;
        }
      }
      p {
        font-size: 14px;
      }
      .first-p {
        margin-bottom: 14px;
      }
    }
  `}
  ${Media.phone`
    padding: 0.96rem 0;
    padding-bottom: 0.78rem;
    .about-box {
      width: 100%;
      gap: 0.6rem;
    }
    .about-image {
      width: 6.46rem;
      height: 3.78rem;
      margin: 0 auto;
    }
    .about-desc {
      width: 6.46rem;
      margin: 0 auto;
      p {
        font-size: 0.28rem;
        line-height: 0.44rem;
      }
      h2 {
        margin: 0 0 0.6rem;
        font-size: 0.48rem;
        :after {
          margin: 11px auto;
        }
      }
      .first-p {
        margin-bottom: 0.22rem;
      }
    }
  `}
`

export const HonorWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 110px;
  position: relative;
  background: url(${HonorBg}) no-repeat;
  background-size: cover !important;
  background-position: center center;
  .list-wrapper {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  h2 {
    color: #212121;
    margin-top: 0;
    margin-bottom: 54px;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
  }
  ${Media.ipad`
    padding: 60px 0 70px 0 ;
    .list-wrapper {
      width: 944px;
    }
  `}
  ${Media.phone`
    padding-top: 0.96rem;
    padding-bottom: 1.08rem;
    h2 {
      margin-bottom: 0.6rem;
      font-size: 0.48rem;
      line-height: 0.72rem;
    }
    .list-wrapper {
      padding: 0;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  `}
`
export const HonorItem = styled.div`
  display: flex;
  width: 378px;
  height: 90px;
  background: #fff;
  border-radius: 10px;
  align-items: center;
  padding-left: 80px;
  :nth-child(1),
  :nth-child(2),
  :nth-child(3) {
    margin-bottom: 32px;
  }
  .box-left {
    margin-right: 20px;
  }
  .box-right {
    p {
      font-size: 16px;
      color: #464646;
      font-weight: 400;
    }
  }
  ${Media.ipad`
    width: 304px;
    padding-left: 40px;
  `}
  ${Media.phone`
    width: 6.08rem;
    height: 1.8rem;
    :nth-child(1),
    :nth-child(2),
    :nth-child(3),
    :nth-child(4),
    :nth-child(5) {
        margin-bottom: 0.3rem;
    }
    padding-left: 0.72rem;
  `}
`

export const ContactUs = styled.div`
  padding-bottom: 100px;
  h2 {
    margin-top: 100px;
    margin-bottom: 54px;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #212121;
  }
  .list-wrapper {
    margin: 0 auto;
    width: 1092px;
    display: flex;
    justify-content: space-between;
  }
  ${Media.ipad`
    padding-bottom: 60px;
    h2{
      margin-top: 60px;
    }
    .list-wrapper{
      width: 944px;
    }
  `}
  ${Media.phone`
    padding-bottom:0.88rem;
    h2 {
      margin: 0.88rem 0 0.6rem;
      font-size: 0.48rem;
      line-height: 0.72rem;
    }
    .list-wrapper {
      flex-direction: column;
      width: 100%;
      align-items: center;
      height: 6.24rem;
    }
  `}
`

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 42px;
  width: 526px;
  height: 140px;
  background: #ffffff;
  box-shadow: 0px 10px 35px 10px rgba(180, 180, 180, 0.1);
  border-radius: 10px;
  .box-left {
    margin-right: 17px;
  }
  .box-right {
    width: 340px;
    margin-left: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      :nth-child(1) {
        color: #212121;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 6px;
      }
      :nth-child(2) {
        color: #878787;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  ${Media.ipad`
    width: 464px;
    height: 148px;
    padding: 0 40px;
    .box-right {
      width: 274px;
    }
  `}
  ${Media.phone`
    width: 6.46rem;
    height: 2.96rem;
    padding: 0 0 0 0.44rem;
    p {
      :nth-child(2) {
        width: 3.4rem;
        font-size: 0.32rem;
      }
    }
    .box-right {
      width: 3.4rem;
      font-size: 0.4rem;
    }
  `}
`
