import React from 'react'
import { Layout, SEO, TitleH1, TitleH2 } from '@raylink/common/components'
import {
  AboutUs,
  BannberWrapper,
  ContactItem,
  ContactUs,
  HonorItem,
  HonorWrapper,
  TitleWrapper,
} from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import AboutUsImage from '../../images/about/about-left.png'

const About = ({ data }) => {
  const GrapghHonorImgSrc = data.HonorImgs?.edges?.map(item => ({
    ...item.node,
  }))

  const HonorList = [
    {
      ...GrapghHonorImgSrc[5],
      title: '美国电影协会TPN安',
      secendTitle: '全认证服务商',
    },
    { ...GrapghHonorImgSrc[0], title: '知识产权管理体系认证' },
    {
      ...GrapghHonorImgSrc[1],
      title: 'ISO27001 数据中心',
      secendTitle: '安全认证',
    },
    { ...GrapghHonorImgSrc[2], title: '国家高新企业' },
    { ...GrapghHonorImgSrc[4], title: '专精特新企业' },
    { ...GrapghHonorImgSrc[3], title: '大数据传输优秀软件' },
  ]

  const GraphContactImhSrc = data.ContactUsImgs?.edges?.map(item => ({
    ...item.node,
  }))

  const ContactList = [
    {
      ...GraphContactImhSrc[1],
      title: '公司地址',
      subTitle: '深圳市南山区商华路2号阳光科创中心B座17层',
    },
    { ...GraphContactImhSrc[0], title: '咨询电话', subTitle: '400-965-5018' },
  ]

  return (
    <Layout canonical="about.html/">
      <SEO
        title="关于RayLink-了解RayLink远程控制服务"
        keywords="远程控制软件,远程办公软件,远控软件,RayLink远程控制软件,RayLink远控软件"
        description="以技术创新为驱动，以优质服务为核心，RayLink致力于为个人及企业用户提供安全稳定、流畅的远控体验，覆盖远程制作、远程办公、远程游戏、远程协助等多种需求场景"
        ogUrl="about.html/"
      />
      <BannberWrapper>
        <TitleWrapper>
          <TitleH1>
            <span className="mark-color">远控无界</span>
            <span className="dot" />
            即在彼此间
          </TitleH1>
          <p>专注于提供超清、稳定、安全、便捷的远控服务</p>
        </TitleWrapper>
      </BannberWrapper>
      <AboutUs>
        <div className="about-box">
          <img
            className="about-image"
            src={AboutUsImage}
            alt=""
            loading="lazy"
          />
          <div className="about-desc">
            <TitleH2>关于我们</TitleH2>
            <p className="first-p">
              RayLink是一款安全、易用、高性能的远程控制软件，广泛应用在桌面远程控制、远程终端访问、网络管理和IT支持等领域。
            </p>
            <p>
              RayLink采用了金融级端到端的加密技术，为用户隐私数据提供严密保护；同时还搭载了行业前沿的高速传输技术，以确保数据传输的实时性与稳定性，让远控画面更高清，操控响应更快速。无论是应用在企业的项目管理，还是个人日常的办公、娱乐，RayLink都能提供稳定的远程技术支持，帮助用户跨越地域障碍，实现灵活、高效的远程作业，提升效率。
            </p>
          </div>
        </div>
      </AboutUs>
      <HonorWrapper>
        <TitleH2>企业荣誉</TitleH2>
        <div className="list-wrapper">
          {HonorList.map(item => (
            <HonorItem key={item.id}>
              <div className="box-left">
                <GatsbyImage
                  image={item?.childImageSharp?.gatsbyImageData}
                  alt={item.name}
                />
              </div>
              <div className="box-right">
                <p>{item.title}</p>
                <p>{item.secendTitle}</p>
              </div>
            </HonorItem>
          ))}
        </div>
      </HonorWrapper>
      <ContactUs>
        <TitleH2>联系我们</TitleH2>
        <div className="list-wrapper">
          {ContactList.map(item => (
            <ContactItem key={item.id}>
              <div className="box-left">
                <GatsbyImage
                  image={item?.childImageSharp?.gatsbyImageData}
                  alt={item.name}
                />
              </div>
              <div className="box-right">
                <p className="contact-box-title">{item.title}</p>
                <p>{item.subTitle}</p>
              </div>
            </ContactItem>
          ))}
        </div>
      </ContactUs>
    </Layout>
  )
}

export default About

export const query = graphql`
  query {
    HonorImgs: allFile(
      filter: {
        relativePath: {
          in: [
            "about/tpn.png"
            "about/intellectual.png"
            "about/iso.png"
            "about/national.png"
            "about/specialization.png"
            "about/raysync.png"
          ]
        }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    ContactUsImgs: allFile(
      filter: {
        relativePath: { in: ["about/vector.png", "about/telephone.png"] }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
